<template>
  <div class="column pt-0">
    <CreateOrUpdateMessageType :is-visible.sync="dialogIsVisible" :type="editableType" @refresh="getTypes" />
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <h2 class="is-size-5 has-text-weight-semibold">Типы подшипников</h2>
      <b-button type="is-info" @click="dialogIsVisible = true">Добавить</b-button>
    </div>
    <MessageTypeTable :data="types" :loading="isLoading" @rowClick="onRowClick" @delete="deleteType" />
    <section v-if="typesCount > 10" class="mt-5 p-3">
      <b-pagination
        :total="typesCount"
        :per-page="count"
        order="is-centered"
        icon-pack="fas"
        :current="page"
        class="base-table__pagination"
        @change="getTypes"
      >
        <template #previous="props">
          <b-pagination-button
            :page="props.page"
            @click="getTypes(page-1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
          </b-pagination-button>
        </template>
        <template #next="props">
          <b-pagination-button
            :page="props.page"
            @click="getTypes(page+1)"
          >
            <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
          </b-pagination-button>
        </template>
      </b-pagination>
    </section>
  </div>
</template>

<script>
import CreateOrUpdateMessageType from "@/components/CMS/Sub-component/CreateOrUpdateMessageType.vue"
import MessageTypeTable from "@/components/CMS/Sub-component/MessageTypeTable.vue"

export default {
  name: "BearingTypeList",
  components: { MessageTypeTable, CreateOrUpdateMessageType },
  data() {
    return {
      page: 1,
      count: 10,
      isLoading: true,
      dialogIsVisible: false,
      types: [],
      typesCount: 0,
      editableType: {}
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    async getTypes(page = 1) {
      this.isLoading = true
      this.page = page || 1
      const offset = (this.page * this.count) - this.count

      try {
        const { data } = await this.$axios.get(`admin/bearings`, {
          params: {
            offset,
            count: this.count
          }
        })
        this.types = data.data || []
        this.typesCount = data?.count ?? 0
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    async deleteType(id) {
      try {
        await this.$axios.delete(`admin/bearings/${id}`)
        await this.getTypes()
      } catch (e) {
        throw new Error(e)
      }
    },
    onRowClick(event) {
      this.editableType = event
      this.dialogIsVisible = true
    },
  },
}
</script>

<style scoped>

</style>
