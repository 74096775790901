<script>
export default {
  name: "MessageTypeTable",
  props: {
    data: Array,
    loading: Boolean,
    showOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [
        { field: "id", label: "ID", },
        { field: "manufacturer", label: "Производитель", },
        { field: "mark", label: "Марка", },
        { field: "BPFO", label: "Частота неисправности наружного кольца, BPFO", },
        { field: "BPFI", label: "Частота неисправности внутреннего кольца, BPFI", },
        { field: "BSF", label: "Частота вращения тел качения, BSF", },
        { field: "FTF", label: "Частота вращения сепаратора, FTF", },
        { field: "deviation", label: "Допустимое отклонение частоты", },
      ],
    }
  },
  methods: {
    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Удаление',
        message: 'Вы действительно хотите удалить?',
        confirmText: 'Удалить',
        cancelText: 'Отмена',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('delete', id)
      })
    }
  }
}
</script>

<template>
  <b-table
    :data="data"
    :loading="loading"
    hoverable
    striped
    class="clickable-table"
    @click="$emit('rowClick', $event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.field"
      :field="column.field"
      :label="column.label"
      v-slot="props"
    >
      <p :class="[column.field === 'text' && 'is-ellipsis']" :title="props.row[column.field]">{{ props.row[column.field] }}</p>
    </b-table-column>
<!--    <b-table-column label="Действия" v-slot="props">-->
<!--      <div>-->
<!--        <ConfirmAction-->
<!--          @confirm="$emit('delete', props.row.id)"-->
<!--        />-->
<!--        &lt;!&ndash;          <b-button type="is-danger is-light" icon-right="trash" @click.stop=""/>&ndash;&gt;-->
<!--      </div>-->
<!--    </b-table-column>-->
    <b-table-column
      v-if="!showOnly"
      :label="'Действия'"
      centered
      v-slot="props"
    >
      <b-button
        class="is-danger"
        icon-right="trash"
        @click.stop.prevent="confirmDelete(props.row.id)"
      />
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">{{ loading ? 'Загрузка...' : 'Нет данных' }}</div>
    </template>
  </b-table>
</template>

<style lang="scss">
.clickable-table {
  tbody > tr {
    cursor: pointer;
  }
  td, th {
    vertical-align: middle;
  }
}
</style>
