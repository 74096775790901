<script>
const FORM_INITIAL = {
  manufacturer: '',
  mark: '',
  BPFO: 0,
  BPFI: 0,
  BSF: 0,
  FTF: 0,
  deviation: 0
}
export default {
  name: "CreateOrUpdateMessageVelModal",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
    type: Object
  },
  data() {
    return {
      form: { ...FORM_INITIAL },
      errors: {},
      isCreating: false
    }
  },
  computed: {
    isEdit() {
      if(!this.type) return false
      return Object.keys(this.type).length
    }
  },
  watch: {
    type(newValue) {
      if(newValue) Object.assign(this.form, newValue)
    }
  },
  methods: {
    async createType() {
      this.errors = {}
      this.isCreating = true
      try {
        if(!this.isEdit) await this.$axios.post('admin/bearings', { ...this.form, agent_id: this.agentId })
        else await this.$axios.put('admin/bearings/' + this.type.id, { ...this.form, agent_id: this.agentId })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.errors = e.error?.response?.data?.errors ?? {}
        throw e
      } finally {
        this.isCreating = false
      }
    },
    closeDialog() {
      Object.assign(this.form, { ...FORM_INITIAL })
      this.$emit('update:isVisible', false)
      this.$emit('update:type', {})
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-3">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-half">
            <b-field label="Производитель" :type="!!errors.manufacturer ? 'is-danger' : ''" :message="errors.manufacturer && errors.manufacturer[0] || ''">
              <b-input v-model="form.manufacturer" />
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Марка" :type="!!errors.mark ? 'is-danger' : ''" :message="errors.mark && errors.mark[0] || ''">
              <b-input v-model="form.mark" />
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Частота неисправности наружного кольца, BPFO" :type="!!errors.BPFO ? 'is-danger' : ''" :message="errors.BPFO && errors.BPFO[0] || ''">
              <b-numberinput v-model="form.BPFO" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Частота неисправности внутреннего кольца, BPFI" :type="!!errors.BPFI ? 'is-danger' : ''" :message="errors.BPFI && errors.BPFI[0] || ''">
              <b-numberinput v-model="form.BPFI" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Частота вращения тел качения, BSF" :type="!!errors.BSF ? 'is-danger' : ''" :message="errors.BSF && errors.BSF[0] || ''">
              <b-numberinput v-model="form.BSF" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Частота вращения сепаратора, FTF" :type="!!errors.FTF ? 'is-danger' : ''" :message="errors.FTF && errors.FTF[0] || ''">
              <b-numberinput v-model="form.FTF" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Допустимое отклонение частоты, Гц" :type="!!errors.deviation ? 'is-danger' : ''" :message="errors.deviation && errors.deviation[0] || ''">
              <b-numberinput v-model="form.deviation" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCreating"
              @click="closeDialog"
            />
            <b-button
              :label="isEdit ? 'Сохранить' : 'Добавить'"
              class="is-info"
              type="submit"
              :loading="isCreating"
              @click="createType"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
